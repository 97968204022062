// variant name marty looks for by default
export const FEATURE_ENABLED_VARIANT_NAME_TREATMENT = 'treatment';
/*
 * Feature Flags
 */
export const FEATURE_BANNER_ADS = 'discovery.sponsored.ads.ab.banner.ads';
export const FEATURE_CALYPSO_AKAMAI_CALL = 'calypso.akamai.call';
export const FEATURE_CHECKOUT_PAYPAL_KILL_SWITCH = 'marty.checkout.3rdpp.paypal.turnOff';
export const FEATURE_CONTENT_SQUARE_KILLSWITCH = 'marty.content.square';
export const FEATURE_EMAIL_SIGNUP_DRAWER = 'marty.ab.email.signup.drawer';
export const FEATURE_GOODS_LABEL_ENDPOINT = 'marty.goods.label.endpoint';
export const FEATURE_HOMEPAGE_BANNER_ADS = 'discovery.homepage.sponsored.ads.ab.banner.ads';
export const FEATURE_KRATOS_CHECKOUT_INIT = 'kratos.checkout.initiate.loader';
export const FEATURE_PDP_STICKY_ADD_TO_CART = 'marty.pdp.remove.add.to.cart.sticky.bottom';
export const FEATURE_LANDING_PAGE_KRATOS = 'marty.landing.page.kratos';
export const FEATURE_LEFT_IN_STOCK = 'search.ab.left.in.stock';
export const FEATURE_MAFIA_AKAMAI = 'marty.mafia.akamai';
export const FEATURE_MARTY_CLIENT_RENDER = 'marty.client.side.render';
export const FEATURE_MY_ACCOUNT_REDESIGN = 'marty.account.redesign';
export const FEATURE_PDP_ACCORDION_ORDER = 'marty.pdp.accordion.order';
export const FEATURE_PERCENT_DISCOUNT_SORT = 'search.ab.percent.discount.sort';
