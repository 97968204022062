import { useEffect, useState } from 'react';

import { cn } from 'helpers/classnames';
import StyleRoomLogo from 'images/zappos/theStyleRoomBlack.svg';
import useMartyContext from 'hooks/useMartyContext';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import { strip } from 'helpers/SearchUtils';
import {
  deleteUserSearchTermsFromLocalStorage,
  saveExplicitSearchEventToLocalStorage,
  updateUserSearchTermsFromLocalStorage
} from 'helpers/searchInputUtils';
import { track } from 'apis/amethyst';
import {
  evSearchAutoCompleteInteraction,
  evSearchZeroStateImpression,
  evSearchZeroStateInteraction,
  evSearchZeroStateTrustedSiteInteraction
} from 'events/headerFooter';

import css from 'styles/components/hf/searchSuggestions.scss';

const ZAPPOS_TRUSTED = 'Shop at other Zappos-trusted sites';
const ZAPPOS_TRUSTED_ZERO = 'Zappos-powered sites';
const NO_SEARCHES = 'No past searches';

interface TrustedSites {
  url: string;
  iconUrl: string;
  text: string;
}

export const getTrustedSites = (): TrustedSites[] => [
  {
    url: 'https://www.zappos.com/c/the-style-room-women',
    iconUrl: StyleRoomLogo,
    text: 'The Style Room'
  }
];

interface Suggestion {
  suggestion: string;
  categories: string[];
}

interface SearchSuggestionsProps {
  suggestions: string[] | Suggestion[];
  termSearched: string;
  suggestionIndex?: number;
  handleZeroStateDelete: () => void;
  isZeroState: boolean;
  isOpen: boolean;
}

export const SearchSuggestions = (props: SearchSuggestionsProps) => {
  const { testId } = useMartyContext();
  const { suggestions, termSearched, suggestionIndex, handleZeroStateDelete, isZeroState, isOpen } = props;
  const [userSearchTerms, setUserSearchTerms] = useState<string[]>(updateUserSearchTermsFromLocalStorage('') ?? []);

  // mimics focus state
  useEffect(() => {
    if (isOpen && isZeroState) {
      const terms = updateUserSearchTermsFromLocalStorage('');
      track(() => [evSearchZeroStateImpression, terms]);
      setUserSearchTerms(terms);
    }
  }, [isOpen, isZeroState]);

  if (!suggestions?.length && !isZeroState) {
    return null;
  }

  const handleClick = (term: string, position: number) => {
    updateUserSearchTermsFromLocalStorage(term);
    if (isZeroState) {
      track(() => [evSearchZeroStateInteraction, { value: term, index: position, isSelected: true }]);
    }

    const autosuggestionClicked = true;
    const autosuggestionShown = true;
    saveExplicitSearchEventToLocalStorage(term, autosuggestionShown, autosuggestionClicked);

    track(() => [evSearchAutoCompleteInteraction, { termSearched, termPosition: position, termSelected: term }]);
  };

  const handleDelete = (deletedTerm: string, index: number) => {
    setUserSearchTerms(deleteUserSearchTermsFromLocalStorage(deletedTerm));
    track(() => [evSearchZeroStateInteraction, { value: deletedTerm, index, isDeleted: true }]);
    handleZeroStateDelete();
  };

  const makeSuggestion = (inSuggestion: string | Suggestion, index: number) => {
    const isCurrentSelection = suggestionIndex === index;
    const className = cn({ [css.active]: isCurrentSelection });
    const testIdStr = testId(`searchSuggestionItem-${index}`);

    const baseProps = {
      'id': isCurrentSelection ? 'headerSearchSuggest' : undefined,
      'role': 'option',
      'aria-selected': isCurrentSelection
    };

    // String, sent when categories=false in our GET call
    if (typeof inSuggestion === 'string') {
      const term = strip(inSuggestion);
      return (
        <li {...baseProps} key={`categoryFalse-${term}`} className={className}>
          <a className={cn(css.link)} data-test-id={testIdStr} onClick={() => handleClick(inSuggestion, index)} href={`/search/${term}`}>
            {inSuggestion}
          </a>
        </li>
      );
    }

    const { suggestion, categories } = inSuggestion;

    // Obj, category present
    // We only show category for the first item if present,
    // otherwise present like the others
    if (suggestion && categories && categories.length && index === 0) {
      const term = strip(suggestion);
      const category = categories[0] || ''; // always grab first one
      return (
        <li {...baseProps} key={`category-${term}`} className={cn(css.categoryItem, className)}>
          <a
            className={cn(css.link)}
            data-test-id={testIdStr}
            onClick={() => handleClick(suggestion, index)}
            href={`/search/${term}/filter/zc2/%22${encodeURIComponent(category)}%22`}
          >
            {suggestion} in <span>{category}</span>
          </a>
        </li>
      );
    }

    // Obj, no cateogry
    if (suggestion) {
      const term = strip(suggestion);
      return (
        <li {...baseProps} key={`objNoCategory-${term}`} className={className}>
          <a className={cn(css.link)} data-test-id={testIdStr} onClick={() => handleClick(suggestion, index)} href={`/search/${term}`}>
            {suggestion}
          </a>
        </li>
      );
    }

    return null;
  };

  const makeUserSuggestions = () => (
    <div className={cn(css.termsContainer, 'searchSuggestions')} data-search-suggestions data-test-id={testId('searchSuggestions')}>
      {userSearchTerms.length === 0 && <div className={css.noPriorSearch}>{NO_SEARCHES}</div>}
      <ul id="hfSearchSuggest" className={css.termsList}>
        {userSearchTerms.map((term, index) => {
          const isCurrentSelection = suggestionIndex === index;
          const baseProps = {
            'id': isCurrentSelection ? 'headerSearchSuggest' : undefined,
            'role': 'option',
            'aria-selected': isCurrentSelection
          };
          const testIdStr = testId(`searchSuggestionItem-${index}`);

          return (
            <li {...baseProps} className={cn(css.termsListItem, { [css.active]: isCurrentSelection })} key={term}>
              <span className={css.recentSearch}>
                <a
                  className={css.term}
                  data-test-id={testIdStr}
                  onClick={() => handleClick(term, index)}
                  href={`/search?term=${strip(term)}`}
                  tabIndex={0}
                >
                  {term}
                </a>
                <button
                  type="button"
                  className={css.removeBtn}
                  aria-label={`Remove ${term}`}
                  onClick={() => handleDelete(term, index)}
                  tabIndex={0}
                />
              </span>
            </li>
          );
        })}
      </ul>
      <div className={css.footer}>
        <hr className="my-4 border-subtle" />
        <p className={css.trusted}>{userSearchTerms.length !== 0 ? ZAPPOS_TRUSTED : ZAPPOS_TRUSTED_ZERO}</p>
        <div className={css.trustedSitesContainer}>
          {getTrustedSites().map(({ url, iconUrl, text }) => (
            <a
              key={text}
              className={css.trustedSitesLink}
              href={url}
              aria-label={text}
              rel="noopener noreferrer"
              target="_blank"
              onClick={() => track(() => [evSearchZeroStateTrustedSiteInteraction, { trustedSiteUrl: url }])}
            >
              <img className={css.logo} src={iconUrl} alt={text} />
            </a>
          ))}
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!isZeroState ? (
        <div data-search-suggestions data-test-id={testId('searchSuggestions')} className={css.container}>
          <ul id="hfSearchSuggest">{suggestions?.map(makeSuggestion)}</ul>
        </div>
      ) : (
        makeUserSuggestions()
      )}
    </>
  );
};

export default withErrorBoundary('SearchSuggestions', SearchSuggestions);
