import ProtoBuf from 'protobufjs';
import type { NextFunction, Request, Response } from 'express';

const cookieProtobuf = `
package zfc;
message Cookie {
  message Session {
    required uint64  timestamp   = 1;
    required uint64  connection  = 2;
    optional uint64  timestamp2  = 3;
  }
  required Session   session     = 1;
  option optimize_for=SPEED;
}
`;

export const COOKIE_NAME = 'zfc';

// getting a zfc session id: https://github01.zappos.net/Integ/zfc/blob/f66eae5bf94bcdc533fc67cd33e277887d97274a/log/common/zfc_packet.cc#L119
export const toPaddedHex = (input: number) => input.toString(16).padStart(16, '0');

const builder = ProtoBuf.parse(cookieProtobuf, { keepCase: true }).root;
const ZfcCookie: any = builder.lookup('zfc.Cookie');

/**
 * Generate a ZFC cookie expires
 */
export const expires = () => {
  const expires = new Date();
  expires.setDate(expires.getDate() + 365 * 30);
  return expires;
};

export const decodeZfcCookie = (value: string) => {
  try {
    return ZfcCookie.decode(Buffer.from(value, 'base64'));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(`Error decoding malformed cookie: ${error} (value: '${value}')`);
  }
};

/**
 * Create the initial ZFC cookie if it does not exist yet
 */
export const createInitialCookie = (sessionId?: string) => {
  let timestamp = +new Date();
  let connection = parseInt((Math.random() * 1e13) as any, 10);
  // if sessionId was provided and is able to be properly parsed, use those values
  if (sessionId) {
    const sessionVals = parseSessionId(sessionId);
    if (sessionVals && sessionVals.connection && sessionVals.timestamp) {
      connection = sessionVals?.connection;
      timestamp = sessionVals?.timestamp;
    }
  }
  const session = ZfcCookie.Session.create({
    timestamp,
    connection,
    timestamp2: +new Date()
  });

  const data = ZfcCookie.create({
    session
  });

  return data;
};

export const encodeCookie = (zfc: string) => Buffer.from(ZfcCookie.encode(zfc).finish()).toString('base64');

export const zfcSessionId = (zfc: {
  session?: {
    timestamp: number | string;
    connection: number | string;
  };
}) => {
  if (!zfc?.session) {
    return;
  }

  return toPaddedHex(Number(zfc.session.timestamp)) + toPaddedHex(Number(zfc.session.connection));
};

export const zfcSessionIdMiddleware = (req: Request, _: Response, next: NextFunction) => {
  const { zfc } = req.cookies;
  const decodedZfc = decodeZfcCookie(zfc);
  if (decodedZfc && decodedZfc.session) {
    req.zfcSessionId = toPaddedHex(decodedZfc.session.timestamp) + toPaddedHex(decodedZfc.session.connection);
  }
  next();
};

/**
 * Given a ZFC Session Id returns the constituent timestamp and connection fields as numbers.
 * The (zfc) session id is made up of two values represented as hex.
 * The first 16 bytes contain the current timestamp and the second 16 bytes contain the nginx “connection id” that served the first page load
 * the timestamp field is sometimes in microseconds (zfc generated), and sometimes in milliseconds (marty generated)
 * @param sessionId
 * @returns {{connection: number, timestamp: number}}
 */
export function parseSessionId(sessionId: string) {
  // take session id, split into two 16 bytes hex strings, and parse to  get timestamp/connection vals
  // TODO what do do if sessionId is malformed? e.g not 32 chars?
  if (sessionId.length !== 32) {
    return;
  }
  const hexTimestamp = sessionId.substring(0, 16);
  const hexConnectionId = sessionId.substring(16);
  const timestamp = parseInt(hexTimestamp, 16);
  const connection = parseInt(hexConnectionId, 16);
  return { timestamp, connection };
}
